import { Box, Text } from "native-base";
import React from "react";
import { Image, ImageSourcePropType, StyleSheet, TextStyle, TouchableOpacity } from "react-native";

import { commonStyles, Scale, VerticalScale } from "../constants";
import type { CategoryFilter } from "../types";

const styles = StyleSheet.create({
  cardContainer: {
    width: Scale(96),
    height: VerticalScale(105),
    padding: Scale(16),
    textAlign: "center",
    marginRight: Scale(8),
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    // borderColor: Colors.disableColor,
    borderRadius: 8,
    shadowColor: "#cbd5e0",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 4,
      height: 8,
    },
  },
  cardText: {
    textAlign: "center",
    fontWeight: "500",
    lineHeight: VerticalScale(18),
    marginTop: VerticalScale(8),
  },
  emojiText: {
    textAlign: "center",
    fontSize: Scale(32),
    lineHeight: Scale(40),
  },
  image: {
    width: Scale(40),
    height: Scale(40),
  },
});

type CommonCategoryCardProps = {
  category: CategoryFilter;
  onPressCard: (value: string) => void;
  isSelected: boolean;
};

const CommonCategoryCard = ({ category, isSelected, onPressCard }: CommonCategoryCardProps): JSX.Element => (
  <TouchableOpacity onPress={() => onPressCard(category.label)} testID={`category-card-${category.value}`}>
    <Box
      style={{
        width: Scale(96),
        height: VerticalScale(105),
        padding: Scale(16),
        marginRight: Scale(8),
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        borderWidth: 1,
        borderStyle: "solid",
        // borderColor: Colors.disableColor,
        borderRadius: 8,
        shadowColor: "#cbd5e0",
        shadowOpacity: 0.2,
        shadowOffset: {
          width: 4,
          height: 8,
        },
      }}
      borderColor={isSelected ? "primary.600" : "gray.400"}
      bgColor={isSelected ? "primary.200" : "white"}
    >
      {category.type === "emoji" ? (
        <Text style={styles.emojiText}>{category.emoji}</Text>
      ) : (
        <Image source={category.image} style={styles.image} resizeMode="contain" />
      )}
      <Text color={"gray.500"} textAlign="center">
        {category.label}
      </Text>
    </Box>
  </TouchableOpacity>
);

export default CommonCategoryCard;
