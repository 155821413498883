import { isLowProteinOrganisation } from "../helpers/userHelpers";
import type { DayOfWeekString } from "../types";
import type { DietEnum, GenderEnum, MealMomentEnum, SustainabilityEnum } from "./backendTypes";

type LegacyMealType = "breakfast" | "lunch" | "snack" | "dinner" | "pre-bed";
const mealTypes: LegacyMealType[] = ["breakfast", "lunch", "snack", "dinner", "pre-bed"];

type LegacyMacroType = "calories" | "protein" | "fat" | "carbs" | "fiber";
const legacyMacroTypes: LegacyMacroType[] = ["calories", "protein", "fat", "carbs", "fiber"];

type Macros = {
  [M in LegacyMacroType]: number;
};

export type LegacyMealState = {
  legacyMealType: LegacyMealType;
  mealMoment: MealMomentEnum;
  size: number;
  macros?: Macros;
  enabled: boolean;
};

export type DaysForNutritionPlan = {
  [D in DayOfWeekString]: boolean;
};

export type LegacyInput = {
  id?: number;
  name: string;
  computedDailyMacros: Macros;
  adjustedDailyMacros?: Macros;
  energyBalance: string;
  meals: LegacyMealState[];
  days: DaysForNutritionPlan;
};

export type LegacyBodyStats = {
  gender: GenderEnum;
  weight: number;
  bodyFatPerc: number;
  leanBodyMass: number;
  tdee: number;
  bmr: number;
  ree: number;
};

export type LegacyFoodPreferences = {
  diet: DietEnum;
  optimize: SustainabilityEnum;
  // intolerances: Intolerance[];
};

const isValidSize = (total: number): boolean => Math.abs(total - 100) < 1e-3;

const computeTotalSize = (meals: LegacyMealState[]): number => {
  let totalSize = 0;
  meals.forEach((m) => {
    if (!m.enabled) return;
    totalSize += m.size;
  });

  return Math.round(totalSize);
};

export const emptyMacros = (): Macros => ({
  calories: 0,
  protein: 0,
  fat: 0,
  carbs: 0,
  fiber: NaN,
});

const roundMacros = (macros: Macros): Macros => ({
  calories: Math.round(macros.calories),
  protein: Math.round(macros.protein),
  fat: Math.round(macros.fat),
  carbs: Math.round(macros.carbs),
  fiber: Math.round(macros.fiber),
});

const scaleMacros = (macros: Macros, scale: number): Macros => {
  const result = { calories: 0, protein: 0, fat: 0, carbs: 0, fiber: 0 };
  legacyMacroTypes.forEach((type) => {
    const macro = macros[type];
    result[type] = Number.isNaN(macro) ? 0 : scale * macro;
  });
  return result;
};

function computeCalories(macros: Macros): number {
  const factors = {
    calories: NaN,
    protein: 4,
    fat: 9,
    carbs: 4,
    fiber: 2,
  };
  return legacyMacroTypes
    .filter((key) => key !== "calories")
    .map((type) => factors[type] * macros[type])
    .filter((x) => !Number.isNaN(x))
    .reduce((a, b) => a + b, 0);
}

const updateCalories = (macros: Macros): Macros => ({
  ...macros,
  calories: computeCalories(macros),
});

const scaleMealMacros = (meals: LegacyMealState[], daily: Macros): Macros[] => {
  const scaled = meals.map((m) => emptyMacros());

  const total = computeTotalSize(meals);

  const lastEnabled = meals.map((m) => m.enabled && m.size > 0).lastIndexOf(true);

  meals.forEach((m, i) => {
    if (!m.enabled) return;

    // Scale the macros using the meal size. Except for the last enabled meal.
    // When the total meal size is not valid, use the scaled version since the
    // remaining macros do not add up to 100%.
    if (i !== lastEnabled || !isValidSize(total)) {
      const macros = roundMacros(scaleMacros(daily, m.size / 100));
      // Re-compute the calories based on the rounded macros. The scaled number
      // of calories is not necessarily the same as the sum of the rounded
      // macros.
      scaled[i] = updateCalories(macros);
      return;
    }

    // Subtract all meal macros from total macros. Use the remaining macros for
    // the last enabled meal. This is needed to avoid accumulating rounding
    // errors caused by macro values that are rounded up or down. Subtracting
    // the previous macros from the daily total implies that all macros add up
    // to the total daily macros.
    const rest: Macros = { ...daily };

    scaled.forEach((macros) => {
      legacyMacroTypes.forEach((type) => {
        rest[type] -= macros[type];
      });
    });

    scaled[i] = rest;
  });

  return scaled;
};

export const computeTotalMacros = (meals: LegacyMealState[]): Macros => {
  const total: Macros = {
    calories: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
    fiber: NaN,
  };

  meals.forEach((m) => {
    if (!m.enabled) return;

    legacyMacroTypes.forEach((type) => {
      total[type] += m.macros ? m.macros[type] : 0;
    });
  });

  return total;
};

const defaultSizes = {
  snack: 10,
  prebed: 20,
  dinnerHigh: 50,
  dinnerMid: 40,
  dinnerLow: 30,
};

export const rebalanceSizes = (meals: LegacyMealState[]): LegacyMealState[] => {
  let total = 100;

  const counts: { [M in LegacyMealType]: number } = {
    breakfast: 0,
    lunch: 0,
    snack: 0,
    dinner: 0,
    "pre-bed": 0,
  };
  // mealTypes.forEach((m) => {
  //   counts[m] = 0;
  // });

  meals.forEach((m) => {
    if (!m.enabled) return;
    counts[m.legacyMealType] += 1;
  });

  // First, subtract the snacks and prebed from the total.
  total -= (counts.snack || 0) * defaultSizes.snack;
  total -= (counts["pre-bed"] || 0) * defaultSizes.prebed;

  // Determine dinner size. High size when breakfast and lunch are omitted.
  // Mid size is used when one but not both are defined. Else, use low size.
  let dinnerSize = defaultSizes.dinnerLow;
  if (!counts.lunch && !counts.breakfast) {
    dinnerSize = total;
    // eslint-disable-next-line no-bitwise
  } else if (counts.lunch ^ counts.breakfast || total >= 90) {
    dinnerSize = defaultSizes.dinnerMid;
  }

  total -= (counts.dinner || 0) * dinnerSize;

  // Split remaining total over breakfast and/or lunch.
  let remainingSize = 0;
  if (counts.lunch || counts.breakfast) {
    remainingSize = total / (counts.lunch + counts.breakfast);
  }

  return meals.map((m) => {
    if (!m.enabled) return { ...m, size: 0, macros: emptyMacros() };

    const size = {
      // Lunch and breakfast split the remaining total.
      breakfast: remainingSize,
      lunch: remainingSize,

      dinner: dinnerSize,

      "pre-bed": defaultSizes.prebed,
      snack: defaultSizes.snack,
    }[m.legacyMealType];

    return {
      ...m,
      size,
    };
  });
};

export const getDefaultMeals = (): LegacyMealState[] =>
  rebalanceSizes([
    {
      legacyMealType: "breakfast",
      mealMoment: "BREAKFAST",
      size: 25,
      enabled: true,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "snack",
      mealMoment: "MORNING_SNACK",
      size: 0,
      enabled: false,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "lunch",
      mealMoment: "LUNCH",
      size: 30,
      enabled: true,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "snack",
      mealMoment: "AFTERNOON_SNACK",
      size: 5,
      enabled: true,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "dinner",
      mealMoment: "DINNER",
      size: 40,
      enabled: true,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "snack",
      mealMoment: "SNACK",
      size: 0,
      enabled: false,
      macros: emptyMacros(),
    },
    {
      legacyMealType: "pre-bed",
      mealMoment: "LATE_SNACK",
      size: 0,
      enabled: false,
      macros: emptyMacros(),
    },
  ]);

const computeOptimalProtein = ({
  bodyFatPerc,
  weight,
  gender,
  leanBodyMass,
  optimize,
}: {
  gender: GenderEnum;
  weight: number;
  bodyFatPerc: number;
  leanBodyMass: number;
  optimize: SustainabilityEnum;
}): number => {
  const lbm = leanBodyMass;

  const lowProtein = isLowProteinOrganisation();

  // Default to this protein
  let factor = {
    MALE: 1.6,
    FEMALE: 1.5,
  }[gender];

  // https://www.healthcouncil.nl/documents/advisory-reports/2021/03/02/dietary-reference-values-for-proteins
  const proteinPerKg = 0.83;

  const leanBodyMassRatio = lbm / weight;

  // NOTE: This will be naturally different for males vs females
  const proteinPerLbm = proteinPerKg / leanBodyMassRatio;

  if (lowProtein) {
    factor = {
      MALE: proteinPerLbm,
      FEMALE: proteinPerLbm,
    }[gender];
  }

  // D16 is 'yes' or 'no'.
  // B15 = IF(D16="yes",2*'CEE'!B13,1.8*'CEE'!B11)
  const constrain =
    bodyFatPerc >
    {
      MALE: 0.2, // if male and bf% > 20%: constrain protein
      FEMALE: 0.3, // if female and bf% > 30%: constrain protein
    }[gender];

  let protein = factor * weight;

  if (constrain && !lowProtein) protein = 2 * lbm;

  return protein;
};

const computeOptimalFat = ({
  gender,
  bmr,
  ree,
  optimize,
}: {
  gender: GenderEnum;
  bmr: number;
  ree: number;
  optimize: SustainabilityEnum;
}): number => {
  // B17 = if(B16="Male",('CEE'!B39*0.4)/9,('CEE'!B41*0.4)/9)
  let factor;
  if (optimize === "SUSTAINABLE") factor = 0.25;
  else factor = 0.4;

  let fat;
  if (gender === "MALE") {
    fat = (bmr * factor) / 9;
  } else {
    fat = (ree * factor) / 9;
  }

  return fat;
};

const computeDailyMacros = ({
  tdee,
  energyBalance,
  diet,
  gender,
  weight,
  bodyFatPerc,
  leanBodyMass,
  bmr,
  ree,
  optimize,
}: {
  tdee: number;
  energyBalance: string;
  diet: DietEnum;
  gender: GenderEnum;
  weight: number;
  bodyFatPerc: number;
  leanBodyMass: number;
  bmr: number;
  ree: number;
  optimize: SustainabilityEnum;
}): Macros => {
  const energyBalanceValue = parseFloat(energyBalance) / 100;
  if (Number.isNaN(energyBalanceValue)) {
    return {
      calories: NaN,
      protein: NaN,
      fat: NaN,
      carbs: NaN,
      fiber: NaN,
    };
  }

  const calories = tdee * energyBalanceValue;

  const optimal = {
    protein: computeOptimalProtein({
      gender,
      weight,
      bodyFatPerc,
      leanBodyMass,
      optimize,
    }),
    fat: computeOptimalFat({ gender, bmr, ree, optimize }),
  };

  const fatAdjustmentFactor = calories > 2800 ? calories / 2600 : 1;

  // B28 = if(D21="Vegan",B15*(2.2/1.8),if(D21="Vegetarian",B15*(2/1.8),B15))
  // TODO use 2.2 for vegan when lysine supplements are taken.
  const proteinFactor = {
    VEGAN: 2.4 / 1.8,
    VEGETARIAN: 2 / 1.8,
    OVO_VEGETARIAN: 2 / 1.8,
    LACTO_VEGETARIAN: 2 / 1.8,
    PESCATARIAN: 1,
    OMNIVORE: 1,
    HALAL: 1,
  }[diet];

  const protein = Math.round(optimal.protein * proteinFactor);

  // TODO Implement keto or obese adjustment?
  // B27 = if(B21=0,(B12-(4*B15)-(9*B17))/4,B22)*(1/B13)
  let carbs = Math.round((calories - 4 * protein - 9 * optimal.fat) / 4 / fatAdjustmentFactor);

  const keto = false;
  if (keto) carbs = 90;

  // TODO Implement fat adjustment?
  // B29 = if(B23=0,B17+((B12-(4*B27)-(4*B28))/9-B17),B24)
  const fat = Math.round((calories - 4 * carbs - 4 * protein) / 9);

  return {
    calories: Math.round(carbs * 4 + protein * 4 + fat * 9),
    protein,
    fat,
    carbs,
    fiber: NaN,
  };
};

// const clearAdjustedDailyMacros = (input: LegacyInput): LegacyInput => {
//   const {
//     // Clear adjustedDailyMacros by removing it from the input object.
//     // eslint-disable-next-line no-unused-vars
//     adjustedDailyMacros,
//     ...other
//   } = input;
//   return other;
// };

export const updateDailyMacros = (
  input: LegacyInput,
  stats: LegacyBodyStats,
  prefs: LegacyFoodPreferences
): LegacyInput => {
  const daily =
    input.adjustedDailyMacros ||
    computeDailyMacros({
      energyBalance: input.energyBalance,
      ...stats,
      ...prefs,
    });

  const scaled = scaleMealMacros(input.meals, daily);

  return {
    ...input,
    computedDailyMacros: daily,
    meals: input.meals.map((m, i) => ({ ...m, macros: scaled[i] })),
  };
};
