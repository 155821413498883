import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
// eslint-disable-next-line import/no-unresolved
import type { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type { JsonMap } from "@segment/analytics-react-native";
import type { ImageSourcePropType } from "react-native";

import type { RecipeCategory, RecipeTag } from "../constants/data";
import type { TAGS } from "../services/backendApi";
import type {
  CalendarDay,
  CalendarItem,
  DietEnum as DietaryPreference,
  FoodIngredientCreateApiArg,
  FoodQuickAddMealCreateApiArg,
  FoodSingleFoodMealCreateApiArg,
  IngredientPost,
  MainGoalEnum,
  MealMomentEnum as MealMoment,
  MealSlotSpecification,
  NutritionDayPlan,
  PlannerAutoPlanDaysCreateApiArg,
  PlannerCalendarDayCreateApiArg,
  PlannerCalendarItemCreateApiArg,
  PlannerPlanSingleFoodMealCreateApiArg,
  PlanSingleFoodMeal,
  QuickAddMeal,
  SetIngredientToAvoid,
  SingleFoodMeal,
  User,
  UserProfile,
  UsersMealSlotSpecificationCreateApiArg,
  UsersNutritionDayPlanCreateApiArg,
  UsersSetIngredientsToAvoidCreateApiArg,
  UsersUserProfileCreateApiArg,
  UsersUserProfileUpdateApiArg,
  UsersWeeklyNutritionPlanCreateApiArg,
  UsersWeeklyNutritionPlanUpdateApiArg,
  WeeklyNutritionPlanCreate,
  WeeklyNutritionPlanUpdate,
} from "../services/backendTypes";

// FIXME: Use types from generated API file where possible.
type MacroName = "kcal" | "fat" | "carbohydrates" | "protein";

enum Macros {
  KCAL = "KCAL",
  FAT = "FAT",
  CARBOHYDRATES = "CARBOHYDRATES",
  PROTEIN = "PROTEIN",
  // TODO: Fiber?
}

type MacroOrG = MacroName | "g";

// FIXME: rename ro MacroDetail
type RecipeMacrosItemType = {
  id?: string;
  macroName: MacroName;
  unit: MacroOrG;
  total: number;
  progress?: number;
};

type IngredientType = {
  id: number;
  name: string;
  optional?: boolean;
  quantity?: number;
  object_id?: number;
  unit?: string;
  kcal?: number;
  protein: number;
};

type NutritionType = {
  calories: string;
  fat: string;
  protein: string;
  carbohydrates: string;
};

type RecipeItemType = {
  id?: number;
  label: string;
  image: ImageSourcePropType;
  longName?: string;
  prepTime?: number;
  ingredients?: RecipeMacrosItemType[];
  recipeMacros: RecipeMacrosItemType[];
};

type RecipeTagOrCategory = RecipeCategory | RecipeTag | "CREATE_RECIPE";

type BaseCategoryFilter = {
  id: string;
  label: string;
  value: RecipeTagOrCategory;
};

type ImageCategoryFilter = BaseCategoryFilter & {
  type: "image";
  image: ImageSourcePropType;
};

type EmojiCategoryFilter = BaseCategoryFilter & {
  type: "emoji";
  emoji: string;
};

type CategoryFilter = ImageCategoryFilter | EmojiCategoryFilter;

enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

enum ActivityEnum {
  SEDENTARY = "SEDENTARY",
  MILDLY_ACTIVE = "MILDLY_ACTIVE",
  ACTIVE = "ACTIVE",
  VERY_ACTIVE = "VERY_ACTIVE",
}

enum SustainabilityEnum {
  SUSTAINABLE = "SUSTAINABLE",
  OPTIMAL = "OPTIMAL",
}

enum DietEnum {
  VEGAN = "VEGAN",
  LACTO_VEGETARIAN = "LACTO_VEGETARIAN",
  OVO_VEGETARIAN = "OVO_VEGETARIAN",
  VEGETARIAN = "VEGETARIAN",
  PESCATARIAN = "PESCATARIAN",
  OMNIVORE = "OMNIVORE",
}

export const DietaryPreferenceEnum: Record<DietaryPreference, DietaryPreference> = {
  HALAL: "HALAL",
  LACTO_VEGETARIAN: "LACTO_VEGETARIAN",
  OMNIVORE: "OMNIVORE",
  OVO_VEGETARIAN: "OVO_VEGETARIAN",
  PESCATARIAN: "PESCATARIAN",
  VEGAN: "VEGAN",
  VEGETARIAN: "VEGETARIAN",
};

export type WeeklyGoal =
  | "LOSE_0_25_KG_PER_WEEK"
  | "LOSE_0_5_KG_PER_WEEK"
  | "LOSE_0_75_KG_PER_WEEK"
  | "LOSE_1_KG_PER_WEEK";

export const WeeklyGoalEnum: Record<WeeklyGoal, WeeklyGoal> = {
  LOSE_0_25_KG_PER_WEEK: "LOSE_0_25_KG_PER_WEEK",
  LOSE_0_5_KG_PER_WEEK: "LOSE_0_5_KG_PER_WEEK",
  LOSE_0_75_KG_PER_WEEK: "LOSE_0_75_KG_PER_WEEK",
  LOSE_1_KG_PER_WEEK: "LOSE_1_KG_PER_WEEK",
};

enum Stress {
  VERY_LOW = "VERY_LOW",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}

enum MeasurementType {
  WEIGHT = "WEIGHT",
  SLEEP = "SLEEP",
  STRESS = "STRESS",
  WAIST_CIRCUMFERENCE = "WAIST_CIRCUMFERENCE",
}

// TODO: Can this be computed from the generated file or taken from Moment.js?
type DayOfWeekString = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

type ClientType = {
  id?: number;
  initials: string;
  fullName: string;
  bgColor?: string;
  textColor?: string;
};

enum ClientsFilterState {
  "ACTIVE_CLIENTS" = "ACTIVE_CLIENTS",
  "DEACTIVATED_CLIENTS" = "DEACTIVATED_CLIENTS",
}

// Generated functions

type PlannerCalendarDayCreate = MutationTrigger<
  MutationDefinition<
    PlannerCalendarDayCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    CalendarDay,
    "api"
  >
>;

type PlannerCalendarItemCreate = MutationTrigger<
  MutationDefinition<
    PlannerCalendarItemCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    CalendarItem,
    "api"
  >
>;

type FoodIngredientCreate = MutationTrigger<
  MutationDefinition<
    FoodIngredientCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    IngredientPost,
    "api"
  >
>;

type CreateSingleFoodMeal = MutationTrigger<
  MutationDefinition<
    FoodSingleFoodMealCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    SingleFoodMeal,
    "api"
  >
>;

type CreateQuickAddMeal = MutationTrigger<
  MutationDefinition<
    FoodQuickAddMealCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    QuickAddMeal,
    "api"
  >
>;

type PlannerPlanSingleFoodMealCreateMutation = MutationTrigger<
  MutationDefinition<
    PlannerPlanSingleFoodMealCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    PlanSingleFoodMeal,
    "api"
  >
>;

export type UpdateUserProfileOnBackend = MutationTrigger<
  MutationDefinition<
    UsersUserProfileUpdateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    UserProfile,
    "api"
  >
>;

export type CreateUserProfileOnBackend = MutationTrigger<
  MutationDefinition<
    UsersUserProfileCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    UserProfile,
    "api"
  >
>;

export type UpdateWeeklyNutritionPlanOnBackend = MutationTrigger<
  MutationDefinition<
    UsersWeeklyNutritionPlanUpdateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    WeeklyNutritionPlanUpdate,
    "api"
  >
>;

export type CreateNutritionDayPlanOnBackend = MutationTrigger<
  MutationDefinition<
    UsersNutritionDayPlanCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    NutritionDayPlan,
    "api"
  >
>;

export type CreateMealSlotSpecificationOnBackend = MutationTrigger<
  MutationDefinition<
    UsersMealSlotSpecificationCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    MealSlotSpecification,
    "api"
  >
>;

export type CreateWeeklyNutritionPlanOnBackend = MutationTrigger<
  MutationDefinition<
    UsersWeeklyNutritionPlanCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    WeeklyNutritionPlanCreate,
    "api"
  >
>;

export type SetIngredientsToAvoidMutation = MutationTrigger<
  MutationDefinition<
    UsersSetIngredientsToAvoidCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    SetIngredientToAvoid,
    "api"
  >
>;

export type AutoPlanDaysOnBackendMutation = MutationTrigger<
  MutationDefinition<
    PlannerAutoPlanDaysCreateApiArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    TAGS,
    unknown,
    "api"
  >
>;

export const MealMomentEnum: Record<MealMoment, MealMoment> = {
  BREAKFAST: "BREAKFAST",
  MORNING_SNACK: "MORNING_SNACK",
  LUNCH: "LUNCH",
  AFTERNOON_SNACK: "AFTERNOON_SNACK",
  DINNER: "DINNER",
  SNACK: "SNACK",
  LATE_SNACK: "LATE_SNACK",
};
export const GoalEnum: Record<MainGoalEnum, MainGoalEnum> = {
  GAIN_MUSCLE: "GAIN_MUSCLE",
  LOSE_WEIGHT: "LOSE_WEIGHT",
  RECOMPOSITION: "RECOMPOSITION",
};

type MacrosPreference = "KETO" | "RECOMMENDED" | "CHOOSE_YOUR_OWN" | "LOW_CARB";
export const MacrosPreferenceEnum: Record<MacrosPreference, MacrosPreference> = {
  KETO: "KETO",
  RECOMMENDED: "RECOMMENDED",
  CHOOSE_YOUR_OWN: "CHOOSE_YOUR_OWN",
  LOW_CARB: "LOW_CARB",
};

export type IntoleranceType = "NO_INTOLERANCE" | "LACTOSE" | "CRUSTACEANS_AND_SHELLFISH" | "GLUTEN" | "NUTS";
export const IntolerancesEnum: Record<IntoleranceType, IntoleranceType> = {
  NO_INTOLERANCE: "NO_INTOLERANCE",
  LACTOSE: "LACTOSE",
  CRUSTACEANS_AND_SHELLFISH: "CRUSTACEANS_AND_SHELLFISH",
  GLUTEN: "GLUTEN",
  NUTS: "NUTS",
};

export type IngredientDislike =
  | "BEEF"
  | "EGGPLANT"
  | "HONEY"
  | "CILANTRO"
  | "MAYONNAISE"
  | "POTATO"
  | "MUSHROOM"
  | "TOMATO"
  | "BRUSSELS_SPROUTS";

export const IngredientDislikesEnum: Record<IngredientDislike, IngredientDislike> = {
  BEEF: "BEEF",
  EGGPLANT: "EGGPLANT",
  HONEY: "HONEY",
  CILANTRO: "CILANTRO",
  MAYONNAISE: "MAYONNAISE",
  POTATO: "POTATO",
  MUSHROOM: "MUSHROOM",
  TOMATO: "TOMATO",
  BRUSSELS_SPROUTS: "BRUSSELS_SPROUTS",
};

export const ingredientDislikeToBackendIdentifier: Record<IngredientDislike, string> = {
  BEEF: "Beef",
  EGGPLANT: "Eggplant",
  HONEY: "Honey",
  CILANTRO: "Cilantro",
  MAYONNAISE: "Mayonnaise",
  POTATO: "Potato",
  MUSHROOM: "Mushroom",
  TOMATO: "Tomato",
  BRUSSELS_SPROUTS: "Brussel Sprouts",
};

export {
  ActivityEnum,
  CategoryFilter,
  ClientsFilterState,
  ClientType,
  CreateQuickAddMeal,
  CreateSingleFoodMeal,
  DayOfWeekString,
  DietEnum,
  FoodIngredientCreate,
  GenderEnum,
  IngredientType,
  MacroName,
  Macros,
  MacrosPreference,
  MeasurementType,
  NutritionType,
  PlannerCalendarDayCreate,
  PlannerCalendarItemCreate,
  PlannerPlanSingleFoodMealCreateMutation,
  RecipeItemType,
  RecipeMacrosItemType,
  RecipeTagOrCategory,
  Stress,
  SustainabilityEnum,
};

type EventId =
  | "click_create_coach_button"
  | "subscription_created"
  | "click_book_a_demo_button"
  | "click_referral_programme_button";

export type AnalyticsEventConstants = {
  groups: {
    [key: string]: {
      group_id: string;

      traits: {
        name: string;
      };
    };
  };

  events: {
    [E in EventId]: {
      name: string;
    };
  };
};

export interface AnalyticsHelpers {
  identifyUserInSegment: (user: User) => void;
  identifyUserInIntercom: (userId: string) => void;
  trackPageOrScreenView: (pageOrScreenName: string, properties?: JsonMap) => void;
  trackEvent: (eventName: string, properties?: JsonMap) => void;
  resetAnalytics: () => void;
  openIntercomMessenger: () => void;
  isIntercomLoaded: () => boolean;
}
